
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { PropertyTaxServiceEstimate } from "@/model/PropertyTaxEstimateResponse";
import PropertyTaxEstimate from "@/model/PropertyTaxEstimate";
import PropertyTaxInfoResponse from "@/model/PropertyTaxInfoResponse";
import { GetTaxInfo, GetEstimate } from "@/utility/ApiService";
import ErrorList from "@/components/ErrorList.vue";
import FormError from "@/model/FormError";
import { eventBus } from "@/main";

@Component({
  components: {
    ErrorList
  }
})
export default class TaxEstimatorSearchForm extends Vue {
  // Allow the API endpoint to be compiled in for each environment
  @Prop({ type: String, default: process.env.VUE_APP_TAX_API_URL })
  apiServer!: string;

  @Prop({ type: Boolean, default: true })
  displayErrorList!: boolean;

  // Keep track of the users' selections
  private yearId = -1;
  private propertyClassId = -1;
  private transitZoneId = -1;
  private fireServicesId = -1;
  private solidWasteId = -1;
  private sewerSurcharge = false;
  private assessmentAmount: number | null = null;

  // The following are used to trigger error messages
  private yearDirty = false;
  private propertyClassDirty = false;
  private transitZoneDirty = false;
  private fireServicesDirty = false;
  private solidWasteDirty = false;
  private assessmentAmountDirty = false;
  private updateTrigger = 0;

  // Keep some additional state for loading and submitting the form
  private taxInfo: PropertyTaxInfoResponse | null = null;
  private waitingForResponse = false;

  private get getCurrentLang() {
    return this.$i18n.locale;
  }

  private get getSubmitButtonDisabledState() {
    return this.waitingForResponse ? "true" : "false";
  }

  private get getYearOptions() {
    const options = {
      labels: [this.$t("selectYear")],
      values: ["-1"]
    };
    const locale = this.getCurrentLang;
    if (this.taxInfo && this.taxInfo.years) {
      this.taxInfo.years.forEach(year => {
        options.labels.push(locale == "fr" ? year.nameFr : year.nameEn);
        options.values.push(`${year.yearId}`);
      });
    }
    return options;
  }

  private get getPropertyClassOptions() {
    const options = {
      labels: [this.$t("selectPropertyClass")],
      values: ["-1"]
    };
    const locale = this.getCurrentLang;
    if (this.taxInfo && this.taxInfo.propertyTypes) {
      this.taxInfo.propertyTypes.forEach(propertyType => {
        options.labels.push(locale == "fr" ? propertyType.nameFr : propertyType.nameEn);
        options.values.push(`${propertyType.propertyTypeId}`);
      });
    }
    return options;
  }

  private get getTransitZoneOptions() {
    return this.serviceSubtypeOptions(this.$t("selectTransitZone") as string, "TR");
  }

  private get getFireServicesOptions() {
    return this.serviceSubtypeOptions(this.$t("selectFireServices") as string, "F");
  }

  private get getSolidWasteOptions() {
    return this.serviceSubtypeOptions(this.$t("selectSolidWaste") as string, "SW");
  }

  private parseAssessmentAmount() {
    // Attempt to convert the input to a number (eg. '$100,000.00')
    if (this.assessmentAmount != null) {
      const parsedNum = parseFloat(
        `${this.assessmentAmount}`.replaceAll("$", "").replaceAll(",", "")
      );
      this.assessmentAmount =
        isNaN(parsedNum) || parsedNum < 1 ? null : Number(parsedNum.toFixed(2));
    }
  }

  private checkSelectFieldError(selectedValue: number, fieldName: string, dirty: boolean) {
    // Don't consider an error if this element is not dirty, or if nothing has been selected
    if (dirty && selectedValue == -1) {
      // Don't consider an error while this element has focus
      if (document.activeElement?.id !== fieldName) {
        return `${this.$t("theField")} "${this.$t(fieldName)}" ${this.$t("isRequired")}`;
      }
    }
    return "";
  }

  private checkAssessmentAmountError() {
    // Don't consider an error if this element is not dirty, or if nothing has been selected
    if (
      this.assessmentAmountDirty &&
      (this.assessmentAmount == null || this.assessmentAmount <= 0)
    ) {
      // Don't consider an error while this element has focus
      if (document.activeElement?.id !== "assessmentAmount") {
        return `${this.$t("theField")} "${this.$t("assessmentAmount")}" ${this.$t(
          "isRequiredNumeric"
        )}`;
      }
    }
    return "";
  }

  private updateErrors() {
    // Update a data member that can be referenced inside a computed method so errors
    // can show on blur events
    this.updateTrigger++;
  }

  private get getFormErrors() {
    // Wrap the error logic so that the blur events will trigger error immediately
    if (this.updateTrigger >= 0) {
      let numErrors = 0;
      const errorMessages: string[] = [];
      const errorTargetIDs: string[] = [];

      // Check all selects for errors
      const selectInputs = ["year", "propertyClass", "transitZone", "fireServices", "solidWaste"];
      selectInputs.forEach(input => {
        const selectedValue: number = this[`${input}Id` as keyof TaxEstimatorSearchForm];
        const dirty: boolean = this[`${input}Dirty` as keyof TaxEstimatorSearchForm];
        const errorMessage = this.checkSelectFieldError(selectedValue, input, dirty);
        if (errorMessage) {
          numErrors++;
          errorMessages.push(errorMessage);
          errorTargetIDs.push(input);
        }
      });

      // Check all textboxes for errors
      const errorMessage = this.checkAssessmentAmountError();
      if (errorMessage) {
        numErrors++;
        errorMessages.push(errorMessage);
        errorTargetIDs.push("assessmentAmount");
      }

      if (numErrors > 0) {
        let formError = new FormError();
        formError.count = numErrors;
        formError.heading = this.$tc("nErrorsWereFound", numErrors);
        formError.errors = errorMessages;
        formError.targets = errorTargetIDs;

        eventBus.$emit("update-form-error", formError);
        return formError;
      }
    }
    eventBus.$emit("update-form-error", null);
    return null;
  }

  private serviceSubtypeOptions(initialSelectLabel: string, subtypeAbbreviation: string) {
    const options = {
      labels: [initialSelectLabel],
      values: ["-1"]
    };
    const locale = this.getCurrentLang;
    if (this.taxInfo && this.taxInfo.services) {
      this.taxInfo.services.forEach(service => {
        if (service.abbreviation == subtypeAbbreviation) {
          if (service.serviceSubtypes) {
            service.serviceSubtypes.forEach(subtype => {
              options.labels.push(locale == "fr" ? subtype.nameFr : subtype.nameEn);
              options.values.push(`${subtype.serviceSubtypeId}`);
            });
          }
        }
      });
    }
    return options;
  }

  private async sleep(milliseconds: number) {
    await new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  private async getSelectElement(elementId: string): Promise<HTMLSelectElement | null> {
    let selectElem: HTMLSelectElement | null = null;
    for (let attempts = 0; selectElem == null && attempts < 20; attempts++) {
      const elem = document.getElementById(elementId) as HTMLSelectElement;
      if (elem) {
        selectElem = elem;
      } else {
        await this.sleep(100);
      }
    }
    return selectElem;
  }

  private async registerHandlersForIE() {
    let selectElem: HTMLSelectElement | null;

    selectElem = await this.getSelectElement("year");
    if (selectElem) {
      selectElem.onchange = event =>
        (this.yearId = Number.parseInt((event.target as HTMLSelectElement).value));
    }

    selectElem = await this.getSelectElement("propertyClass");
    if (selectElem) {
      selectElem.onchange = event =>
        (this.propertyClassId = Number.parseInt((event.target as HTMLSelectElement).value));
    }

    selectElem = await this.getSelectElement("transitZone");
    if (selectElem) {
      selectElem.onchange = event =>
        (this.transitZoneId = Number.parseInt((event.target as HTMLSelectElement).value));
    }

    selectElem = await this.getSelectElement("fireServices");
    if (selectElem) {
      selectElem.onchange = event =>
        (this.fireServicesId = Number.parseInt((event.target as HTMLSelectElement).value));
    }

    selectElem = await this.getSelectElement("solidWaste");
    if (selectElem) {
      selectElem.onchange = event =>
        (this.solidWasteId = Number.parseInt((event.target as HTMLSelectElement).value));
    }
  }

  private async mounted() {
    // Setup manual reactions for select boxes in IE11, since they do not fire with v-model
    if (
      window.navigator.userAgent.includes("MSIE ") ||
      window.navigator.userAgent.includes("Trident/")
    ) {
      await this.registerHandlersForIE();
    }
  }

  private async created() {
    if (this.displayErrorList) {
      eventBus.$on("focus-form-error", () => {
        (this.$refs.errorList as ErrorList).focus();
      });
    }

    try {
      this.taxInfo = await GetTaxInfo(this.apiServer);
    } catch {
      console.error("Unable to load tax info");
    }
  }

  private async getEstimate() {
    // Set all the required elements as dirty so that they will generate errors as needed
    this.yearDirty = this.propertyClassDirty = this.transitZoneDirty = this.fireServicesDirty = this.solidWasteDirty = this.assessmentAmountDirty = true;

    // Only search if there are no validation errors
    if (this.getFormErrors) {
      // Focus on the error list
      eventBus.$emit("focus-form-error");
    } else {
      try {
        // Set some state so that the submit button will be disabled
        this.waitingForResponse = true;

        const estimateResponse = await GetEstimate(
          this.apiServer,
          this.yearId,
          this.propertyClassId,
          this.transitZoneId,
          this.fireServicesId,
          this.solidWasteId,
          this.sewerSurcharge,
          this.assessmentAmount == null ? 0.0 : this.assessmentAmount
        );

        if (estimateResponse.errorMessage != null && estimateResponse.errorMessage.length > 0) {
          throw estimateResponse.errorMessage;
        } else {
          this.notifySearchComplete(estimateResponse.serviceEstimates);
        }
      } catch (err) {
        console.error(`Error while searching: ${err}`);
        this.notifySearchError(this.$t("genericSearchError") as string);
      } finally {
        this.waitingForResponse = false;
      }
    }
  }

  @Emit()
  notifySearchComplete(serviceEstimates: PropertyTaxServiceEstimate[]): PropertyTaxEstimate {
    const estimate = new PropertyTaxEstimate();
    estimate.serviceEstimates = serviceEstimates;
    estimate.amount = this.assessmentAmount ? this.assessmentAmount : 0.0;

    if (this.taxInfo != null && this.taxInfo.years != null) {
      estimate.year = this.taxInfo.years.find(year => year.yearId == this.yearId);
    }

    return estimate;
  }

  @Emit()
  notifySearchError(message: string): string {
    return message;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import PropertyTaxAssessmentResponse, {
  PropertyInformation
} from "@/model/PropertyTaxAssessmentResponse";

@Component({})
export default class TaxAssessmentDetail extends Vue {
  @Prop({ default: null }) assessment?: PropertyTaxAssessmentResponse;
  @Prop({ default: null }) property?: PropertyInformation;

  private get getCurrentLang() {
    return this.$i18n.locale;
  }

  private get getPropertyFullAddress() {
    const property = this.property;
    if (property !== null && property !== undefined) {
      const { unit, streetNumber, qual, streetName } = property;
      const unitPart = unit ? unit + "-" : "";
      const qualPart = qual ? qual : "";

      return `${unitPart}${streetNumber}${qualPart} ${streetName}`;
    } else {
      return "";
    }
  }

  private get getAssessmentTableColumns() {
    return [this.$t("class"), this.$t("currentValueAssessment")];
  }

  private get getTaxInfoTableColumns() {
    return [this.$t("levies"), this.$t("taxesAndFees")];
  }

  private get getAssessmentTableRowData() {
    return (yearId: string) => {
      if (this.assessment == null) {
        return [];
      } else if (this.assessment !== undefined && (yearId === "year1" || yearId === "year2")) {
        const assessment = this.assessment as PropertyTaxAssessmentResponse;
        // Roll-up the results
        const lang = this.getCurrentLang;
        const rows: string[][] = [];

        const year_class_desc_assess = assessment.year_class_desc_assess.filter(e => {
          return e.yearNo === assessment[yearId];
        });
        const descriptionProp = lang == "en" ? "en_desc" : "fr_desc";

        year_class_desc_assess.forEach(e => {
          const classDescription = e[descriptionProp] + " - " + e.className;
          rows.push([classDescription, this.formatAmount(e.assessmentValue, lang, true)]);
        });

        if (yearId === "year1") {
          rows.push([
            this.$t("totalAssess") as string,
            this.formatAmount(assessment.totalAssess1, lang, true)
          ]);
        } else if (this.assessment.totalAssess2 !== null) {
          rows.push([
            this.$t("totalAssess") as string,
            this.formatAmount(assessment.totalAssess2, lang, true)
          ]);
        }
        return rows;
      }
    };
  }

  private get getTaxInfoTableRowData() {
    return (yearId: string) => {
      if (this.assessment == null) {
        return [];
      } else if (this.assessment !== undefined && (yearId === "year1" || yearId === "year2")) {
        const assessment = this.assessment as PropertyTaxAssessmentResponse;
        // Roll-up the results
        const lang = this.getCurrentLang;
        let rows: string[][] = [];

        if (yearId === "year1") {
          rows = [
            [this.$t("muniAmt") as string, this.formatAmount(assessment.muniAmt1, lang, false)],
            [this.$t("eduAmt") as string, this.formatAmount(assessment.eduAmt1, lang, false)],
            [this.$t("solidAmt") as string, this.formatAmount(assessment.solidAmt1, lang, false)],
            [this.$t("stormAmt") as string, this.formatAmount(assessment.stormAmt1, lang, false)],
            [this.$t("otherAmt") as string, this.formatAmount(assessment.otherAmt1, lang, false)],
            [
              this.$t("totalLevied") as string,
              this.formatAmount(assessment.totalLevied1, lang, false)
            ]
          ];
        } else if (this.assessment.totalAssess2 !== null) {
          // Only need to build the rows if the second year assessment exists
          rows = [
            [this.$t("muniAmt") as string, this.formatAmount(assessment.muniAmt2, lang, false)],
            [this.$t("eduAmt") as string, this.formatAmount(assessment.eduAmt2, lang, false)],
            [this.$t("solidAmt") as string, this.formatAmount(assessment.solidAmt2, lang, false)],
            [this.$t("stormAmt") as string, this.formatAmount(assessment.stormAmt2, lang, false)],
            [this.$t("otherAmt") as string, this.formatAmount(assessment.otherAmt2, lang, false)],
            [
              this.$t("totalLevied") as string,
              this.formatAmount(assessment.totalLevied2, lang, false)
            ]
          ];
        }
        return rows;
      }
    };
  }

  private formatAmount(amount: number, lang: string, isAssessmentValue: boolean): string {
    let options = {};
    if (isAssessmentValue) {
      options = {
        minimumFractionDigits: 0
      };
    } else {
      options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
    }
    if (lang === "fr") {
      return new Intl.NumberFormat("fr-FR", options).format(amount) + " $";
    } else {
      return "$" + new Intl.NumberFormat("en-US", options).format(amount);
    }
  }
}

import { render, staticRenderFns } from "./TaxEstimatorSearchResults.vue?vue&type=template&id=66838313&"
import script from "./TaxEstimatorSearchResults.vue?vue&type=script&lang=ts&"
export * from "./TaxEstimatorSearchResults.vue?vue&type=script&lang=ts&"
import style0 from "./TaxEstimatorSearchResults.vue?vue&type=style&index=0&id=66838313&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
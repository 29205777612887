
import { Component, Vue, Prop } from "vue-property-decorator";
import PropertyTaxEstimate from "@/model/PropertyTaxEstimate";
import { PropertyTaxServiceEstimate } from "@/model/PropertyTaxEstimateResponse";
import { IsMobile } from "@/utility/Context";

@Component({})
export default class TaxEstimatorSearchResults extends Vue {
  @Prop({ default: null }) estimate?: PropertyTaxEstimate;
  @Prop({ default: null }) message?: string;

  private isMobile: boolean = IsMobile();

  private get getCurrentLang() {
    return this.$i18n.locale;
  }

  private get getDisclaimerTitle() {
    if (this.estimate != null && this.estimate.year != null) {
      return this.$t("disclaimer", {
        year: this.getCurrentLang == "fr" ? this.estimate.year.nameFr : this.estimate.year.nameEn
      });
    }
    return "";
  }

  private get getDisclaimerText() {
    if (this.estimate != null && this.estimate.year != null) {
      return this.getCurrentLang == "fr"
        ? this.estimate.year.disclaimerFr
        : this.estimate.year.disclaimerEn;
    }
    return "";
  }

  private get getEstimateTitle() {
    if (this.estimate != null && this.estimate.amount > 0.0) {
      const formatter = new Intl.NumberFormat(this.getCurrentLang, {
        style: "currency",
        currency: "USD"
      });
      return this.$t("estimate", {
        amount: formatter.format(this.estimate.amount).replace("US", "")
      });
    }
    return "";
  }

  private get getTableColumns() {
    return [this.$t("service"), this.$t("rate"), this.$t("amount")];
  }

  private get getTableRowData() {
    if (this.estimate == null) {
      return [];
    }

    // Roll-up the results
    const lang = this.getCurrentLang;
    const services = ["CIT", "POL", "CTL", "CAL", "TR", "F", "RSS", "SW"];
    const rows: string[][] = [];
    let subtotalPercent = 0.0;
    let subtotalAmount = 0.0;
    services.forEach(service => {
      const serviceEstimate = this.findServiceEstimate(service);
      if (serviceEstimate != null) {
        rows.push(
          this.createServiceRow(
            service,
            this.$t(`${service}.tooltip`, lang) as string,
            `${this.$t("revealTooltip")}: ${this.$t(service)} ${this.$t("service")}` as string,
            serviceEstimate,
            lang
          )
        );
        subtotalPercent += serviceEstimate.percentageIsFixed ? 0.0 : serviceEstimate.percentage;
        subtotalAmount += serviceEstimate.amount;
      }
    });

    // Add a subtotal
    rows.push([
      this.formatCellContent(this.$t("subtotal", lang).toString(), "strong"),
      this.formatCellContent(this.formatPercent(subtotalPercent), "strong"),
      this.formatCellContent(this.formatAmount(subtotalAmount, lang), "strong")
    ]);

    // Get the education service
    const educationAbbreviation = "EDN";
    const serviceEstimate = this.findServiceEstimate(educationAbbreviation);
    if (serviceEstimate != null) {
      rows.push(
        this.createServiceRow(
          educationAbbreviation,
          this.$t(`${educationAbbreviation}.tooltip`, lang) as string,
          `${this.$t("revealTooltip")}: ${this.$t(educationAbbreviation)} ${this.$t(
            "service"
          )}` as string,
          serviceEstimate,
          lang
        )
      );
      subtotalPercent += serviceEstimate.percentageIsFixed ? 0.0 : serviceEstimate.percentage;
      subtotalAmount += serviceEstimate.amount;
    }

    // Add a total
    rows.push([
      this.formatCellContent(this.$t("total", lang).toString(), "strong"),
      this.formatCellContent(this.formatPercent(subtotalPercent), "strong"),
      this.formatCellContent(this.formatAmount(subtotalAmount, lang), "strong")
    ]);

    return rows;
  }

  private formatCellContent(cellContent: string, formatter: string) {
    return `<${formatter}>${cellContent}</${formatter}>`;
  }

  private createServiceRow(
    service: string,
    tooltip: string,
    tooltipAriaLabel: string,
    estimate: PropertyTaxServiceEstimate,
    lang: string
  ): string[] {
    const strPercent = estimate.percentageIsFixed
      ? this.$t("fixed", lang).toString()
      : this.formatPercent(estimate.percentage);
    const strAmount = this.formatAmount(estimate.amount, lang);
    let tooltipElement = "";
    if (tooltip && tooltip != "") {
      tooltipElement = `<ott-tooltip lang="${lang}" content="${tooltip}" aria-label="${tooltipAriaLabel}"></ott-tooltip>`;
    }
    return [this.$t(service, lang).toString() + tooltipElement, strPercent, strAmount];
  }

  private formatPercent(percent: number): string {
    return percent.toFixed(6);
  }

  private formatAmount(amount: number, lang: string): string {
    let strAmount = `${amount.toFixed(2)}`;
    if (lang == "fr") {
      strAmount = `${strAmount.replace(".", ",")}$`;
    } else {
      strAmount = `$${strAmount}`;
    }
    return strAmount;
  }

  private get getMessage() {
    return this.message == null ? "" : this.message;
  }

  private get searchCompleted() {
    return this.estimate != null;
  }

  private get searchHasMessage() {
    return this.message != null && this.message.length > 0;
  }

  private findServiceEstimate(serviceAbbreviation: string): PropertyTaxServiceEstimate | null {
    let desiredEstimate = null;
    if (this.estimate != null && this.estimate.serviceEstimates != null) {
      const found = this.estimate.serviceEstimates.find(
        estimate =>
          estimate.serviceAbbreviation != null &&
          estimate.serviceAbbreviation == serviceAbbreviation
      );
      if (found) {
        desiredEstimate = found;
      }
    }
    return desiredEstimate;
  }

  private mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = IsMobile();
    });
  }
}


import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import RecaptchaV3 from "@/components/RecaptchaV3.vue";
import ErrorList from "@/components/ErrorList.vue";
import FormError from "@/model/FormError";
import { eventBus } from "@/main";
import {
  GetPropertyAssessmentByRollNumber,
  GetPropertyCandidatesByAddress,
  GetPropertyCandidatesByRollNumber
} from "@/utility/ApiService";
import PropertyTaxAssessmentResponse from "@/model/PropertyTaxAssessmentResponse";
import { PropertyInformation, PropertyDetails } from "@/model/PropertyTaxAssessmentResponse";

@Component({
  components: {
    RecaptchaV3,
    ErrorList
  }
})
export default class TaxAssessmentSearchForm extends Vue {
  // Allow the API endpoint to be compiled in for each environment
  @Prop({ type: String, default: process.env.VUE_APP_TAX_API_URL })
  apiServer!: string;

  @Prop({ type: Boolean, default: true })
  displayErrorList!: boolean;

  @Prop({ type: String, default: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
  recaptcha_site_key!: string;

  private assessmentTermOfServiceDirty = false;
  private propertyStreetNumberDirty = false;
  private propertyStreetNameDirty = false;
  private propertyRollNumberDirty = false;
  private assessmentTermOfService = false;
  private updateTrigger = 0;

  private propertyRollNumber = "";
  private propertyStreetName = "";
  private propertyStreetNumber = "";
  private propertyUnit = "";
  private propertyQualifier = "";

  private waitingForResponse = false;

  private selectedAssessmentSearchOption = "";
  private get getAssessmentSearchOptions() {
    const options = {
      labels: [
        this.$t("taxAssessmentSearchOption.address"),
        this.$t("taxAssessmentSearchOption.rollNumber"),
        this.$t("taxAssessmentSearchOption.vacantParcelLand")
      ],
      values: ["address", "rollnumber", "vacant"]
    };

    return options;
  }

  private created() {
    if (this.displayErrorList) {
      eventBus.$on("focus-form-error", () => {
        (this.$refs.errorList as ErrorList).focus();
      });
    }
  }

  private get displaySearchForm() {
    return (
      this.selectedAssessmentSearchOption === "address" ||
      this.selectedAssessmentSearchOption === "rollnumber"
    );
  }

  private handleRollNumberBlur() {
    if (this.propertyRollNumber !== "") {
      this.propertyRollNumberDirty = true;
      this.updateTrigger++;
    }
  }

  private updateAssessmentSearchOption(event: Event) {
    // Clear errors
    this.assessmentTermOfServiceDirty = this.propertyStreetNumberDirty = this.propertyStreetNameDirty = this.propertyRollNumberDirty = false;

    const target = event.target as HTMLInputElement;
    if (target && target.value) {
      this.selectedAssessmentSearchOption = target.value;
    }

    // Display warning when user select Vacant Parcel of land option
    // otherwise reset previous results or error messages
    if (target.value === "vacant") {
      this.notifyPropertySearchError("taxAssessmentVacantMessage");
    } else {
      this.notifyPropertySearchStart();
    }
  }

  private get getCurrentLang() {
    return this.$i18n.locale;
  }

  private get getSubmitButtonDisabledState() {
    return this.waitingForResponse ? "true" : "false";
  }

  private get getSubmitButtonLabel() {
    return this.waitingForResponse
      ? `
        <span class="ott-spinner" role="status" aria-hidden="true"></span>
        <span aria-hidden="true">${this.$t("Searching...")}</span>
        <span class="sr-only"> ${this.$t("searchInProgress")}</span>
        `
      : `
        ${this.$t("Search")}<span class="sr-only"> ${this.$t("searchSpec")}</span>
        `;
  }

  private filterDigits() {
    // Remove non-digit characters from the input and update accordingly
    this.propertyStreetNumber = this.propertyStreetNumber.replace(/\D/g, "");

    const propertyStreetNumberInput = document.getElementById("propertyStreetNumber");
    if (propertyStreetNumberInput !== undefined) {
      (propertyStreetNumberInput as HTMLInputElement).value = this.propertyStreetNumber;
    }
  }

  private updateErrors() {
    // Update a data member that can be referenced inside a computed method so errors
    // can show on blur events
    this.updateTrigger++;
  }

  private get getFormErrors() {
    // Wrap the error logic so that the blur events will trigger error immediately
    if (this.updateTrigger >= 0) {
      let numErrors = 0;
      const errorMessages: string[] = [];
      const errorTargetIDs: string[] = [];

      const streetNumberErrorMessage = this.checkPropertyStreetNumberError();
      if (streetNumberErrorMessage) {
        numErrors++;
        errorMessages.push(streetNumberErrorMessage);
        errorTargetIDs.push("propertyStreetNumber");
      }

      const streetNameErrorMessage = this.checkPropertyStreetNameError();
      if (streetNameErrorMessage) {
        numErrors++;
        errorMessages.push(streetNameErrorMessage);
        errorTargetIDs.push("propertyStreetName");
      }

      const rollNumberErrorMessage = this.checkPropertyRollNumberError();
      if (rollNumberErrorMessage) {
        numErrors++;
        errorMessages.push(rollNumberErrorMessage);
        errorTargetIDs.push("propertyRollNumber");
      }

      const termOfServiceErrorMessage = this.checkTermOfServiceError();

      if (termOfServiceErrorMessage) {
        numErrors++;
        errorMessages.push(termOfServiceErrorMessage);
        errorTargetIDs.push("assessmentTermOfService");
      }

      if (numErrors > 0) {
        let formError = new FormError();
        formError.count = numErrors;
        formError.heading = this.$tc("nErrorsWereFound", numErrors);
        formError.errors = errorMessages;
        formError.targets = errorTargetIDs;

        eventBus.$emit("update-form-error", formError);
        return formError;
      }
    }

    eventBus.$emit("update-form-error", null);
    return null;
  }

  private async getRecaptchaToken(action: string) {
    return (this.$refs.recaptcha as RecaptchaV3).getRecaptchaToken(action);
  }

  private async getPropertyInformation() {
    // Set all the required elements as dirty so that they will generate errors as needed
    this.assessmentTermOfServiceDirty = this.propertyStreetNumberDirty = this.propertyStreetNameDirty = this.propertyRollNumberDirty = true;
    if (this.getFormErrors) {
      // Focus on the error list
      eventBus.$emit("focus-form-error");
    } else {
      this.notifyPropertySearchStart();
      if (this.selectedAssessmentSearchOption === "rollnumber") {
        try {
          // Set some state so that the submit button will be disabled
          this.waitingForResponse = true;
          eventBus.$emit("waiting-for-response", true);
          const getPropertyToken = await this.getRecaptchaToken("GetProperty");
          const properties = await GetPropertyCandidatesByRollNumber(
            this.apiServer,
            this.propertyRollNumber,
            getPropertyToken
          );

          const getAssessmentToken = await this.getRecaptchaToken("GetAssessment");
          const assessment = await GetPropertyAssessmentByRollNumber(
            this.apiServer,
            this.propertyRollNumber,
            getAssessmentToken
          );

          this.notifyPropertySearchComplete(properties[0], assessment);
        } catch (err) {
          console.error(`Error while searching: ${err}`);
          this.notifyPropertySearchError("rollNumberNotFoundMsg");
        } finally {
          this.waitingForResponse = false;
          eventBus.$emit("waiting-for-response", false);
        }
      } else if (this.selectedAssessmentSearchOption === "address") {
        try {
          // Set some state so that the submit button will be disabled
          this.waitingForResponse = true;
          eventBus.$emit("waiting-for-response", true);

          const getPropertyToken = await this.getRecaptchaToken("GetProperty");
          const properties = await GetPropertyCandidatesByAddress(
            this.apiServer,
            this.propertyStreetNumber,
            this.propertyStreetName,
            this.propertyQualifier,
            this.propertyUnit,
            getPropertyToken
          );
          if (properties.length == 1) {
            const getAssessmentToken = await this.getRecaptchaToken("GetAssessment");
            const assessment = await GetPropertyAssessmentByRollNumber(
              this.apiServer,
              properties[0].rollNumber,
              getAssessmentToken
            );
            this.notifyPropertySearchComplete(properties[0], assessment);
          }
          if (properties.length > 1) {
            this.notifyPropertySearchError("multiplePropertiesFoundMsg");
          }
        } catch (err) {
          console.error(`Error while searching: ${err}`);
          this.notifyPropertySearchError("addressNotFoundMsg");
        } finally {
          this.waitingForResponse = false;
          eventBus.$emit("waiting-for-response", false);
        }
      }
    }
  }

  private checkPropertyStreetNumberError() {
    if (
      this.selectedAssessmentSearchOption === "address" &&
      this.propertyStreetNumberDirty &&
      this.propertyStreetNumber === ""
    ) {
      return `${this.$t("theField")} "${this.$t("propertyStreetNumber")}" ${this.$t("isRequired")}`;
    }
    return "";
  }

  private checkPropertyStreetNameError() {
    if (
      this.selectedAssessmentSearchOption === "address" &&
      this.propertyStreetNameDirty &&
      this.propertyStreetName === ""
    ) {
      return `${this.$t("theField")} "${this.$t("propertyStreetName")}" ${this.$t("isRequired")}`;
    }
    return "";
  }

  private checkPropertyRollNumberError() {
    let errorMsg = "";
    const pattern = /^0614\.\d{3}\.\d{3}\.\d{5}\.\d{4}$/;
    if (this.selectedAssessmentSearchOption === "rollnumber" && this.propertyRollNumberDirty) {
      if (this.propertyRollNumber === "") {
        errorMsg = `${this.$t("theField")} "${this.$t("propertyRollNumber")}" 
        ${this.$t("isRequired")}`;
      } else if (!pattern.test(this.propertyRollNumber)) {
        errorMsg = `${this.$t("propertyRollNumberPatternError")}`;
      }
    }
    return errorMsg;
  }

  private checkTermOfServiceError() {
    if (this.assessmentTermOfServiceDirty && this.assessmentTermOfService === false) {
      return `${this.$t("theField")} "${this.$t("assessmentTermOfServiceLabel")}" ${this.$t(
        "isRequired"
      )}`;
    }
    return "";
  }

  @Emit()
  notifyPropertySearchComplete(
    property: PropertyInformation,
    assessment: PropertyTaxAssessmentResponse
  ): PropertyDetails {
    const propertyDetails = new PropertyDetails();
    propertyDetails.property = property;
    propertyDetails.assessment = assessment;

    return propertyDetails;
  }

  @Emit()
  notifyPropertySearchError(message: string): string {
    return message;
  }

  @Emit()
  notifyPropertySearchStart() {
    return;
  }
}

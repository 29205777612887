
import { Component, Vue, Prop } from "vue-property-decorator";
import TaxEstimatorSearchForm from "./TaxEstimatorSearchForm.vue";
import TaxEstimatorSearchResults from "./TaxEstimatorSearchResults.vue";
import PropertyTaxEstimate from "@/model/PropertyTaxEstimate";
import { ScrollAndShowById } from "@/utility/Scrolling";

@Component({
  components: {
    TaxEstimatorSearchForm,
    TaxEstimatorSearchResults
  }
})
export default class TaxEstimatorWidget extends Vue {
  // Allow the API endpoint to be compiled in for each environment
  @Prop({ type: String, default: process.env.VUE_APP_TAX_API_URL })
  apiServer!: string;

  @Prop({ type: Boolean, default: true })
  displayErrorList!: boolean;

  private estimate: PropertyTaxEstimate | null = null;
  private message: string | null = null;

  public get getCurrentLang() {
    return this.$i18n.locale;
  }

  private focusResults() {
    ScrollAndShowById("search-results");
  }

  private clearStatus() {
    this.estimate = null;
    this.message = null;
  }

  private onNotifySearchComplete(estimate: PropertyTaxEstimate) {
    this.clearStatus();
    this.estimate = estimate;
    this.focusResults();
  }

  private onNotifySearchError(message: string) {
    this.clearStatus();
    this.message = message;
    this.focusResults();
  }
}

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import i18n from "@/i18n";
import RouterView from "@/components/RouterView.vue";
import Home from "@/views/Home.vue";
import TaxAssessment from "@/views/TaxAssessment.vue";
import TaxEstimator from "@/views/TaxEstimator.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:locale",
    component: RouterView,
    children: [
      {
        path: "",
        component: Home,
        meta: { title: "appTitle" }
      },
      {
        path: "taxestimator",
        component: TaxEstimator,
        meta: { title: "propertyTaxEstimatorTitle" }
      },
      {
        path: "testimateurstaxes",
        component: TaxEstimator,
        meta: { title: "propertyTaxEstimatorTitle" }
      },
      {
        path: "taxassessmentlookup",
        component: TaxAssessment,
        meta: { title: "propertyTaxAssessmentTitle" }
      },
      {
        path: "outiltaxesevaluation",
        component: TaxAssessment,
        meta: { title: "propertyTaxAssessmentTitle" }
      }
    ]
  },
  {
    // Catch-all
    path: "*",
    redirect: `/${i18n.fallbackLocale}`
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  const parsedLocale = to.params.locale;
  // If the lang is not recognized, redirect to the fallback.
  if (!i18n.availableLocales.includes(parsedLocale)) {
    next(`/${i18n.fallbackLocale}`);
  } else {
    // Set the i18n locale
    i18n.locale = parsedLocale;
    next();
  }
});

// Ensure we set the html element's lang attribute and the title to reflect the current locale
router.afterEach(to => {
  let title = i18n.t("appTitle", i18n.locale) as string;
  // Check if the route has a meta title, and use it if available
  if (to.meta && to.meta.title) {
    title = i18n.t(to.meta.title, i18n.locale) as string;
  }

  document.title = i18n.t(title, i18n.locale) as string;
  document.documentElement.setAttribute("lang", i18n.locale);
});

import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const fallbackLocale = "en";
let pageLocale = fallbackLocale;
if (document && document.documentElement && document.documentElement.lang) {
  const htmlLocale = document.documentElement.lang;
  // Check if the locale string (eg. 'fr-CA', 'fr') include 'fr', otherwise
  // use the fallback ('en').
  if (typeof htmlLocale !== "undefined" && htmlLocale !== null && htmlLocale.includes("fr")) {
    pageLocale = "fr";
  }
}

export default new VueI18n({
  locale: pageLocale,
  fallbackLocale: fallbackLocale,
  messages: loadLocaleMessages()
});

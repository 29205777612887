
import { Component, Vue, Prop } from "vue-property-decorator";
import TaxAssessmentSearchForm from "./TaxAssessmentSearchForm.vue";
import TaxAssessmentSearchResult from "./TaxAssessmentSearchResult.vue";
import { ScrollAndShowById } from "@/utility/Scrolling";
import PropertyTaxAssessmentResponse from "@/model/PropertyTaxAssessmentResponse";
import { PropertyInformation, PropertyDetails } from "@/model/PropertyTaxAssessmentResponse";

@Component({
  components: {
    TaxAssessmentSearchForm,
    TaxAssessmentSearchResult
  }
})
export default class TaxAssessmentWidget extends Vue {
  // Allow the API endpoint to be compiled in for each environment
  @Prop({ type: String, default: process.env.VUE_APP_TAX_API_URL })
  apiServer!: string;

  @Prop({ type: Boolean, default: true })
  displayErrorList!: boolean;

  private property: PropertyInformation | null = null;
  private assessment: PropertyTaxAssessmentResponse | null = null;
  private message: string | null = null;

  private focusResults() {
    ScrollAndShowById("search-results");
  }

  private clearStatus() {
    this.property = null;
    this.assessment = null;
    this.message = null;
  }

  private onNotifyPropertySearchStart() {
    this.clearStatus();
  }

  private onNotifyPropertySearchComplete(propertyDetails: PropertyDetails) {
    this.clearStatus();
    this.property = propertyDetails.property;
    this.assessment = propertyDetails.assessment;
    this.focusResults();
  }

  private onNotifyPropertySearchError(message: string) {
    this.clearStatus();
    this.message = message;
    this.focusResults();
  }
}


import { Component, Vue } from "vue-property-decorator";
import TaxEstimatorWidget from "@/components/TaxEstimatorWidget.vue";
import TaxAssessmentWidget from "@/components/TaxAssessmentWidget.vue";
import ErrorList from "@/components/ErrorList.vue";
import FormError from "@/model/FormError";
import { Components } from "ottuxlib";
import OttawaTopButton = Components.OttawaTopButton;
import { InferApiServer } from "@/utility/Context";
import { eventBus } from "@/main";

@Component({
  components: {
    TaxEstimatorWidget,
    TaxAssessmentWidget,
    ErrorList,
    OttawaTopButton
  }
})
export default class Home extends Vue {
  private selectedTaxWidgetOption = "";
  private inferApiServer: string = InferApiServer();
  private formError: FormError | null = null;

  public get getCurrentLang() {
    return this.$i18n.locale;
  }

  private get getTaxWidgetOption() {
    const options = {
      labels: [this.$t("taxWidgetOption.estimator"), this.$t("taxWidgetOption.assessment")],
      values: ["taxestimator", "taxassessmentlookup"]
    };

    return options;
  }

  private updateTaxWidgetOption(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.value) {
      this.selectedTaxWidgetOption = target.value;

      // Update route query param
      const query = { t: this.$t(target.value).toString() };
      this.$router.replace({ query });
    }
  }

  private created() {
    const selected = this.$route.query.t as string;
    this.selectedTaxWidgetOption = this.$t(selected).toString();

    eventBus.$on("update-form-error", (error: FormError) => {
      this.formError = error;
    });

    eventBus.$on("focus-form-error", () => {
      (this.$refs.errorList as ErrorList).focus();
    });
  }

  private mounted() {
    // Wait for the page to fully load, then select the radio button based on the URL parameter.
    window.onload = () => {
      const selected = this.selectedTaxWidgetOption;
      if (selected) {
        const selectedElement = document.getElementById("taxWidgetOption-" + selected);
        if (selectedElement) {
          (selectedElement as HTMLInputElement).checked = true;
          (selectedElement as HTMLInputElement).setAttribute("tabindex", "0");
        }
      }
    };
  }

  private get getFormError() {
    return this.formError;
  }
}

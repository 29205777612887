
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class RecaptchaV3 extends Vue {
  @Prop({ type: String, default: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
  site_key!: string;

  private get currentLocale(): string {
    return this.$i18n.locale;
  }

  private mounted() {
    this.loadRecaptchaScript();
    this.recaptchaA11y();
  }

  private loadRecaptchaScript() {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.site_key}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  private recaptchaA11y() {
    /**
     * https://dev.azure.com/cityofottawa/One%20City%20One%20Team/_boards/board/t/CWE%20-%20Citizen%20Web%20Experience/Stories/?workitem=12548
     * Change dynamically the language of the ReCaptcha
     *
     * @param iframeGoogleCaptcha
     * @param lang
     */
    function setCaptchaLang(iframeGoogleCaptcha: HTMLIFrameElement, lang: string) {
      lang = lang || "en";
      let currentRecaptchaLang = "en";
      const src = iframeGoogleCaptcha.getAttribute("src");
      if (iframeGoogleCaptcha !== null && src !== null) {
        const langMatch = src.match(/hl=(.*?)&/);
        if (langMatch !== null) {
          currentRecaptchaLang = langMatch.pop() as string;
        }

        if (lang !== currentRecaptchaLang) {
          iframeGoogleCaptcha.setAttribute("src", src.replace(/hl=(.*?)&/, "hl=" + lang + "&"));
        }
      }
    }

    // Accessibility fix for recaptcha
    const checkReCaptcha = setInterval(() => {
      const textarea = document.getElementById("g-recaptcha-response-100000");
      const badge = document.getElementsByClassName("grecaptcha-badge")[0];

      if (badge !== null && badge !== undefined && badge.parentElement !== null) {
        const container = badge.parentElement;
        container.setAttribute("role", "region");
        container.setAttribute("aria-label", "reCaptcha");
      }

      if (textarea !== null) {
        textarea.setAttribute("aria-hidden", "true");
        textarea.setAttribute("aria-label", "do not use");
        textarea.setAttribute("aria-readonly", "true");

        // Inline frames must have a description to summarize their visual content.
        // See: https://dev.azure.com/cityofottawa/CWE/_boards/board/t/Citizen%20Web%20Experience/Stories/?workitem=4353
        let recaptchaNum = 1;
        const recaptchaParents = document.getElementsByClassName("grecaptcha-logo");
        if (recaptchaParents !== null && recaptchaParents.length > 0) {
          for (const parentElem of recaptchaParents) {
            const recaptchaIframes = parentElem.getElementsByTagName("iframe");
            if (recaptchaIframes !== null && recaptchaIframes.length > 0) {
              setCaptchaLang(recaptchaIframes[0], this.currentLocale);
              for (const recaptchaElem of recaptchaIframes) {
                recaptchaElem.removeAttribute("role");
                recaptchaElem.setAttribute("aria-label", `reCaptcha ${recaptchaNum}`); // Does not need i18n
                recaptchaNum++;
              }
            }
          }
        }
        clearInterval(checkReCaptcha);
      }
    });
  }

  public async getRecaptchaToken(action: string) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const grecaptcha = await (window as any).grecaptcha;
    const token = await grecaptcha.execute(this.site_key, { action: action });
    return token;
  }
}

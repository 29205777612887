
import { Component, Vue } from "vue-property-decorator";
import TaxEstimatorWidget from "@/components/TaxEstimatorWidget.vue";
import { Components } from "ottuxlib";
import OttawaTopButton = Components.OttawaTopButton;
import { InferApiServer } from "@/utility/Context";

@Component({
  components: {
    TaxEstimatorWidget,
    OttawaTopButton
  }
})
export default class TaxEstimator extends Vue {
  private inferApiServer: string = InferApiServer();

  public get getCurrentLang() {
    return this.$i18n.locale;
  }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";

import "ott-cwl/dist/ott-cwl/ott-cwl.css";
import { applyPolyfills, defineCustomElements } from "ott-cwl/loader";

Vue.config.productionTip = false;

// Tell Vue to ignore all components defined in our custom library
// and bind the custom elements to the window object
Vue.config.ignoredElements = [/ott-\w*/];
applyPolyfills().then(() => {
  defineCustomElements();
});

export const eventBus = new Vue();

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");

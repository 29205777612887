
import { Component, Vue } from "vue-property-decorator";
import TaxAssessmentWidget from "@/components/TaxAssessmentWidget.vue";
import { Components } from "ottuxlib";
import OttawaTopButton = Components.OttawaTopButton;
import { InferApiServer } from "@/utility/Context";

@Component({
  components: {
    TaxAssessmentWidget,
    OttawaTopButton
  }
})
export default class TaxAssessment extends Vue {
  private inferApiServer: string = InferApiServer();

  public get getCurrentLang() {
    return this.$i18n.locale;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import TaxAssessmentDisclaimer from "@/components/TaxAssessmentDisclaimer.vue";
import TaxAssessmentAdditionalInfo from "@/components/TaxAssessmentAdditionalInfo.vue";
import TaxAssessmentDetail from "@/components/TaxAssessmentDetail.vue";
import PropertyTaxAssessmentResponse, {
  PropertyInformation
} from "@/model/PropertyTaxAssessmentResponse";
import { eventBus } from "@/main";

@Component({
  components: { TaxAssessmentDisclaimer, TaxAssessmentAdditionalInfo, TaxAssessmentDetail }
})
export default class TaxAssessmentSearchResult extends Vue {
  @Prop({ default: null }) assessment?: PropertyTaxAssessmentResponse;
  @Prop({ default: null }) property?: PropertyInformation;
  @Prop({ default: null }) message?: string;

  private isWaitingForResponse = false;
  private get getMessage() {
    return this.message == null ? "" : this.$t(this.message);
  }

  private get searchPropertyCompleted() {
    return this.property != null;
  }

  private get searchHasMessage() {
    return this.message != null && this.message.length > 0;
  }

  private get getProperty() {
    return this.property;
  }

  private get getAssessment() {
    return this.assessment;
  }

  private created() {
    eventBus.$on("waiting-for-response", this.onWaitingForResponseEvent);
  }

  private destroyed() {
    eventBus.$off("waiting-for-response", this.onWaitingForResponseEvent);
  }

  private onWaitingForResponseEvent(waiting: boolean) {
    setTimeout(() => {
      this.isWaitingForResponse = waiting;
    }, 100);
  }

  private get getIsWaitingForResponse() {
    return this.isWaitingForResponse;
  }
}

/**
 * A wrapper obect to represent a successful assessment
 */
export default class PropertyTaxAssessmentResponse {
  public rollNumber = "";
  public year_class_desc_assess: TaxAssessmentInfo[] = [];
  public year1 = -1;
  public totalAssess1 = 0.0;
  public muniAmt1 = 0.0;
  public eduAmt1 = 0.0;
  public solidAmt1 = 0.0;
  public stormAmt1 = 0.0;
  public otherAmt1 = 0.0;
  public totalLevied1 = 0.0;
  public year2 = -1;
  public totalAssess2 = 0.0;
  public muniAmt2 = 0.0;
  public eduAmt2 = 0.0;
  public solidAmt2 = 0.0;
  public stormAmt2 = 0.0;
  public otherAmt2 = 0.0;
  public totalLevied2 = 0.0;
}

/**
 * A wrapper obect to represent a full property report
 */

export class PropertyDetails {
  public property: PropertyInformation | null = null;
  public assessment: PropertyTaxAssessmentResponse | null = null;
}

export class PropertyInformation {
  public rollNumber = "";
  public unit = "";
  public qual = "";
  public streetNumber = "";
  public streetName = "";
}

export class TaxAssessmentInfo {
  public yearNo = -1;
  public className = "";
  public en_desc = "";
  public fr_desc = "";
  public assessmentValue = 0.0;
}

export class PropertyTaxApiServiceError {
  public type = "";
  public title = "";
  public traceId = "";
  public status = -1;
}

import axios, { AxiosResponse } from "axios";
import PropertyTaxInfoResponse from "@/model/PropertyTaxInfoResponse";
import PropertyTaxEstimateResponse from "@/model/PropertyTaxEstimateResponse";
import PropertyTaxAssessmentResponse, {
  PropertyInformation
} from "@/model/PropertyTaxAssessmentResponse";

async function makeWebServiceCall<T>(
  apiUrl: string,
  params: object = {}
): Promise<AxiosResponse<T>> {
  // Don't trap errors - allow them to bubble up.
  return (await axios.get(apiUrl, { params })) as AxiosResponse<T>;
}

async function makePostWebServiceCall<T>(
  apiUrl: string,
  params: object = {}
): Promise<AxiosResponse<T>> {
  // Don't trap errors - allow them to bubble up.
  return (await axios.post(apiUrl, { params })) as AxiosResponse<T>;
}

function createParam(paramName: string, paramVal: string) {
  // Don't create a param for empty values
  if (!paramVal) {
    return "";
  }
  return `${paramName}=${encodeURIComponent(paramVal.trim())}`;
}

function cleanApiServer(apiServer: string) {
  return apiServer.endsWith("/") ? apiServer : apiServer + "/";
}

export async function GetTaxInfo(apiServer: string): Promise<PropertyTaxInfoResponse> {
  const apiUrl = `${cleanApiServer(apiServer)}estimate/info`;
  const response = await makeWebServiceCall<PropertyTaxInfoResponse>(apiUrl);

  return response.data;
}

export async function GetPropertyCandidatesByRollNumber(
  apiServer: string,
  rollNumber: string,
  recaptchaToken: string
): Promise<PropertyInformation[]> {
  const apiUrl = `${cleanApiServer(
    apiServer
  )}api/Property/RollNumber/${rollNumber}?recaptchaToken=${recaptchaToken}`;
  const response = await makePostWebServiceCall<PropertyInformation[]>(apiUrl);

  return response.data;
}

export async function GetPropertyCandidatesByAddress(
  apiServer: string,
  streetNumber: string,
  streetName: string,
  qual: string,
  unit: string,
  recaptchaToken: string
): Promise<PropertyInformation[]> {
  // Create the parameters for the api call
  const params = [
    createParam("streetNumber", `${streetNumber}`),
    createParam("streetName", `${streetName}`),
    createParam("qual", `${qual}`),
    createParam("unit", `${unit}`),
    createParam("recaptchaToken", `${recaptchaToken}`)
  ];
  // Don't search on empty parameters
  const queryString = params.filter(param => param.length > 0).join("&");
  const apiUrl = `${cleanApiServer(apiServer)}api/property/Address?${queryString}`;
  const response = await makePostWebServiceCall<PropertyInformation[]>(apiUrl);

  return response.data;
}

export async function GetPropertyAssessmentByRollNumber(
  apiServer: string,
  rollNumber: string,
  recaptchaToken: string
): Promise<PropertyTaxAssessmentResponse> {
  const apiUrl = `${cleanApiServer(
    apiServer
  )}api/assessment/RollNumber/${rollNumber}?recaptchaToken=${recaptchaToken}`;
  const response = await makePostWebServiceCall<PropertyTaxAssessmentResponse>(apiUrl);

  return response.data;
}

export async function GetEstimate(
  apiServer: string,
  yearId: number,
  propertyClassId: number,
  transitZoneId: number,
  fireServicesId: number,
  solidWasteId: number,
  sewerSurcharge: boolean,
  assessmentAmount: number
): Promise<PropertyTaxEstimateResponse> {
  // Create the parameters for the api call
  const params = [
    createParam("yearId", `${yearId}`),
    createParam("propertyClassId", `${propertyClassId}`),
    createParam("transitZoneId", `${transitZoneId}`),
    createParam("fireServicesId", `${fireServicesId}`),
    createParam("solidWasteId", `${solidWasteId}`),
    createParam("sewerSurcharge", `${sewerSurcharge}`),
    createParam("assessmentAmount", `${assessmentAmount}`)
  ];
  // Don't search on empty parameters
  const queryString = params.filter(param => param.length > 0).join("&");
  const apiUrl = `${cleanApiServer(apiServer)}estimate?${queryString}`;
  const response = await makeWebServiceCall<PropertyTaxEstimateResponse>(apiUrl);

  return response.data;
}

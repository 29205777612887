import { PropertyTaxServiceEstimate } from "@/model/PropertyTaxEstimateResponse";
import { PropertyTaxYear } from "@/model/PropertyTaxInfoResponse";

/**
 * A wrapper obect to represent a successful estimate
 */
export default class PropertyTaxEstimate {
  public serviceEstimates: PropertyTaxServiceEstimate[] = [];
  public year?: PropertyTaxYear;
  public amount = 0.0;
}

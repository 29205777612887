export function IsIE(): boolean {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    return true;
  }
  return false;
}

export function ClientWidth(): number {
  return document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
}

export function IsMobile(width = 512): boolean {
  return ClientWidth() < width;
}

export function InferApiServer() {
  // For local builds, and hosted builds using a `*.k8s*.` based path, use VUE_APP_TAX_API_URL
  const localhostRegex = /^localhost/;
  const ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/;
  const k8sRegex = /.*\.k8s.*\..*/;
  const loc = window.location;
  if (
    localhostRegex.test(loc.hostname) ||
    ipRegex.test(loc.hostname) ||
    k8sRegex.test(loc.hostname)
  ) {
    return process.env.VUE_APP_TAX_API_URL;
  }
  // Otherwise, use an api path based on the origin
  return `${loc.origin}/api`;
}

import { IsIE } from "./Context";

export default function ScrollAndShow(elem: HTMLElement) {
  if (elem != null) {
    // Focus the element
    elem.focus({ preventScroll: false });

    // Since the header has a fixed height, offset by that as well
    const header = document.getElementById("ott-header-container");
    if (header != null) {
      const height = header.getBoundingClientRect().height;
      const elemTop = elem.getBoundingClientRect().top;
      const scrollY = Math.trunc(elemTop - height - 10);
      setTimeout(() => {
        IsIE()
          ? window.scrollBy(0, scrollY)
          : window.scrollBy({ left: 0, top: scrollY, behavior: "smooth" });
      }, 100);
    }
  }
}

export function ScrollAndShowById(elemId: string) {
  const elem = document.getElementById(elemId);
  ScrollAndShow(elem as HTMLElement);
}


import { Component, Vue, Prop } from "vue-property-decorator";
import FormError from "@/model/FormError";
import ScrollAndShow from "@/utility/Scrolling";

@Component({})
export default class ErrorList extends Vue {
  @Prop({ type: Boolean, default: true })
  displayErrorList!: boolean;

  @Prop({ type: FormError, default: null })
  formError!: FormError;

  public focus() {
    setTimeout(() => {
      const errorList = this.$refs.errorlist as HTMLSelectElement;
      if (errorList) {
        const errorListSection = errorList.getElementsByTagName("section");
        if (errorListSection && errorListSection.length > 0) {
          ScrollAndShow(errorListSection[0]);
        }
      }
    }, 100);
  }
}

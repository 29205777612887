
import { Component, Vue, Prop } from "vue-property-decorator";
import { Components } from "ottuxlib";
import OttawaHeader = Components.OttawaHeader;
import OttawaFooter = Components.OttawaFooter;
import { IsMobile } from "@/utility/Context";

@Component({
  components: {
    OttawaHeader,
    OttawaFooter
  }
})
export default class App extends Vue {
  @Prop({ type: String, default: process.env.VUE_APP_OTTAWA_CA_URL })
  apiServer!: string;

  private isMobile: boolean = IsMobile();

  public get getCurrentLang() {
    return this.$i18n.locale;
  }

  private get getBreadcrumbs() {
    return {
      labels: [
        this.$t("breadcrumb1Label"),
        this.$t("breadcrumb2Label"),
        this.$t("breadcrumb3Label"),
        this.$t("breadcrumb4Label"),
        this.$t("breadcrumb5Label"),
        this.$t("propertyTaxApp")
      ],
      links: [
        this.$t("breadcrumb1Link"),
        this.$t("breadcrumb2Link"),
        this.$t("breadcrumb3Link"),
        this.$t("breadcrumb4Link"),
        this.$t("breadcrumb5Link")
      ]
    };
  }

  private async switchLang(newLang: string) {
    const currentRoute = this.$route;
    const query = { ...currentRoute.query };

    this.$i18n.locale = newLang;

    // Translate path & query parameters
    const path = this.$t(currentRoute.path.toString()).toString();

    for (const key in query) {
      query[key] = this.$t(query[key].toString()).toString();
    }

    // Construct the new newRoute with the translated query parameters
    const newRoute = {
      path: path,
      query: query
    };

    // Use the router to replace the window location, and restore the scroll location after
    const scrollPos = window.scrollY ? window.scrollY : 0;
    await this.$router.replace(newRoute);
    setTimeout(() => {
      window.scroll(0, scrollPos);
    }, 100);
  }

  private loadGtag() {
    const head = document.head || document.getElementsByTagName("head")[0];
    const gtagScript = document.createElement("script");

    gtagScript.setAttribute("src", "/js/gtag.js");
    gtagScript.async = true;
    gtagScript.charset = "utf-8";

    head.appendChild(gtagScript);
  }

  private mounted() {
    if (process.env.VUE_APP_ENV === "prod") {
      this.loadGtag();
    }

    window.addEventListener("resize", () => {
      this.isMobile = IsMobile();
    });
  }
}
